import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { UpdateAppointmentPayload } from "@/types/interfaces";
import Store from "../index";

const endpoint = `scheduling`;

const updatePayloadShell = {
    userId: "",
    title: "",
    startTime: undefined,
    endTime: undefined,
    description: "",
    includeInCompanyProfile: false,
    includeInSchedule: false,
    invitations: []
};

@Module({
    dynamic: true,
    store: Store,
    name: "SchedulingModule",
    namespaced: true
})
export default class SchedulingModule extends VuexModule {
    itemsWithRsvpYesResponses: Array<string> = [];
    itemsWithRsvpNoResponses: Array<string> = [];
    appointmentUpdatePayload: UpdateAppointmentPayload = updatePayloadShell;

    // @Mutation
    // public setAppointmentUpdatePayload(data = {}) {
    //     this.appointmentUpdatePayload = data;
    // }

    @Mutation
    public clearRsvps() {
        // these RSVP arrays are only needed while the user is interacting with the schedule...
        // ... in order to fake real time functionality.
        // e.g. the schedule is NOT currently refetched when a change like invite "accept/decline" occurs.
        // so when the user navigates away from the schedule these arrays should be reset.
        // on the next load the schedule will have all the appropraite statuses of the schedule items.
        this.itemsWithRsvpYesResponses = [];
        this.itemsWithRsvpNoResponses = [];
    }

    @Mutation
    public addRsvpItem(item: string) {
        this.itemsWithRsvpYesResponses.push(item);
    }

    @Mutation
    public addRsvpNoItem(item: string) {
        this.itemsWithRsvpNoResponses.push(item);
    }

    @Action({ rawError: true })
    async acceptAppointment(id: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`${endpoint}/${id}/accept`, null, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    async declineAppointment(id: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`${endpoint}/${id}/decline`, null, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    async postAppointment(payload: UpdateAppointmentPayload = {}) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(`${endpoint}`, payload, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    async editAppointment(payload: UpdateAppointmentPayload = {}) {
        const token = this.context.rootGetters.idToken;
        const { exAppId, ...rest } = payload;
        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`${endpoint}/${exAppId}`, rest, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
